import DashboardLayout from 'src/layouts/dashboard';
import { AuthGuard, MFAGuard } from 'src/auth/guard';
import { lazy, Suspense } from 'react';
import { LoadingScreen } from 'src/components/loading-screen';
import { Outlet } from 'react-router-dom';

const TenantUsers = lazy(() => import('src/pages/tenant/users'));
const TenantSettings = lazy(() => import('src/pages/tenant/settings'));

export const tenantRoutes = [
  {
    path: 'tenant',
    element: (
      <AuthGuard>
        <MFAGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </MFAGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: 'users',
        element: <TenantUsers />,
      },
      {
        path: 'settings',
        element: <TenantSettings />,
      },
    ],
  },
];

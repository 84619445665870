import { useState, useEffect } from 'react';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { SplashScreen } from 'src/components/loading-screen';

import { useAuthContext } from '../hooks';

type Props = {
  children: React.ReactNode;
};

export default function MFAGuard({ children }: Props) {
  const { loading } = useAuthContext();

  return <>{loading ? <SplashScreen /> : <Container>{children}</Container>}</>;
}

// ----------------------------------------------------------------------

function Container({ children }: Props) {
  const router = useRouter();

  const { mfa } = useAuthContext();

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    // Skip MFA check in test environment
    if (!mfa && import.meta.env.VITE_TEST !== '1') {
      const searchParams = new URLSearchParams({
        returnTo: window.location.pathname,
      }).toString();

      router.replace(`${paths.auth.jwt.login}?${searchParams}`);
    } else {
      setChecked(true);
    }
  }, [mfa, router]);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}

import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
}

// SETUP COLORS

export const grey = {
  0: '#FFFFFF',
  50: '#FAFAFA',
  100: '#F5F5F5',
  200: '#E5E5E5',
  300: '#D4D4D4',
  400: '#A3A3A3',
  500: '#737373',
  600: '#525252',
  700: '#404040',
  800: '#262626',
  900: '#191919',
  950: '#0A0A0A',
};

export const empBluePrimary = {
  50: '#F0F7FF',
  100: '#E1EFFF',
  200: '#B8DDFF',
  300: '#85C3FF',
  400: '#52A9FF',
  500: '#2589FF',
  600: '#0063F7',
  700: '#0052D6',
  800: '#0043B0',
  900: '#003785',
  950: '#001B44',
};

export const empGreen = {
  50: '#F2FFE6',
  100: '#E6FFD1',
  200: '#CCFF99',
  300: '#99FF47',
  400: '#66FF1A',
  500: '#33CC00',
  600: '#2DB300',
  700: '#218A00',
  800: '#1B7300',
  900: '#165C00',
  950: '#0A3300',
};

export const empYellow = {
  50: '#fffdea',
  100: '#fff6c5',
  200: '#ffee85',
  300: '#ffdf46',
  400: '#ffcd1b',
  500: '#ffab00',
  600: '#e28200',
  700: '#bb5a02',
  800: '#984508',
  900: '#7c390b',
  950: '#481c00',
};

export const empRed = {
  50: '#fff2ed',
  100: '#ffe2d4',
  200: '#ffc0a8',
  300: '#ff9471',
  400: '#ff5630',
  500: '#fe3311',
  600: '#ef1907',
  700: '#c60c08',
  800: '#9d0f11',
  900: '#7e1011',
  950: '#44060a',
};

export const empBlueSecondary = {
  50: '#f0faff',
  100: '#dff3ff',
  200: '#b9e9fe',
  300: '#7bdafe',
  400: '#34c7fc',
  500: '#0ab2ed',
  600: '#008ecb',
  700: '#006c9c',
  800: '#056087',
  900: '#0a4f70',
  950: '#07324a',
};

export const primary = {
  lighter: empBluePrimary[100],
  light: empBluePrimary[200],
  main: empBluePrimary[600],
  dark: empBluePrimary[700],
  darker: empBluePrimary[800],
  contrastText: grey[0],
};

export const secondary = {
  lighter: empBlueSecondary[50],
  light: empBlueSecondary[100],
  main: empBlueSecondary[500],
  dark: empBlueSecondary[600],
  darker: empBlueSecondary[700],
  contrastText: grey[0],
};

export const info = {
  lighter: empBluePrimary[50],
  light: empBluePrimary[200],
  main: empBluePrimary[500],
  dark: empBluePrimary[700],
  darker: empBluePrimary[900],
  contrastText: grey[0],
};

export const success = {
  lighter: empGreen[100],
  light: empGreen[400],
  main: empGreen[600],
  dark: empGreen[700],
  darker: empGreen[900],
  contrastText: grey[0],
};

export const warning = {
  lighter: empYellow[50],
  light: empYellow[200],
  main: empYellow[500],
  dark: empYellow[700],
  darker: empYellow[900],
  contrastText: grey[800],
};

export const error = {
  lighter: empRed[50],
  light: empRed[200],
  main: empRed[500],
  dark: empRed[700],
  darker: empRed[900],
  contrastText: grey[0],
};

export const common = {
  black: grey[900],
  white: '#FFFFFF',
};

const action = {
  hover: alpha(grey[500], 0.08),
  selected: alpha(grey[500], 0.16),
  disabled: alpha(grey[500], 0.8),
  disabledBackground: alpha(grey[500], 0.24),
  focus: alpha(grey[500], 0.24),
  hoverOpacity: 0.08,
  disabledOpacity: 0.48,
};

const base = {
  primary,
  secondary,
  info,
  success,
  warning,
  error,
  grey,
  common,
  divider: grey[200],
  action,
  empBluePrimary,
  empBlueSecondary,
  empGreen,
  empYellow,
  empRed,
};

// ----------------------------------------------------------------------

export function palette(mode: 'light' | 'dark') {
  const light = {
    ...base,
    mode: 'light',
    text: {
      primary: grey[800],
      secondary: grey[600],
      disabled: grey[400],
    },
    background: {
      paper: common.white,
      default: common.white,
      page: grey[50],
      neutral: grey[200],
    },
    action: {
      ...base.action,
      active: grey[600],
    },
  };

  return light;
}

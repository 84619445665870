import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

import Main from './main';
import SideNav from './side-nav';
import GlobalHeader from './global-header';
import { useNavData } from './config-navigation';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function DashboardLayout({ children }: Props) {
  const { navItems } = useNavData();
  const location = useLocation();

  const activeSectionData =
    navItems.find((obj) => obj.children.some((item) => location.pathname.includes(item.path))) ||
    navItems[0];

  const activeSectionID = activeSectionData.id;
  const sideNavData = activeSectionData.children;

  return (
    <>
      <GlobalHeader activeSectionID={activeSectionID} navItems={navItems} />
      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          bgcolor: 'background.page',
        }}
      >
        <SideNav sideNavData={sideNavData} />
        <Main sx={{ minHeight: 1, bgcolor: 'background.page', width: '100%' }}>{children}</Main>
      </Box>
    </>
  );
}

import axios from 'axios';

import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const endpoints = {
  auth: {
    me: '/users/profile',
    users: '/users',
    login: '/auth/login',
    register: '/auth/register',
    beginResetPassword: '/auth/begin-reset-password',
    resetPassword: '/auth/reset-password',
    onboard: '/auth/onboard',
    invitationSignup: '/auth/invitation-signup',
    userSecret: '/users/secret',
  },
  adverse: {
    stats: {
      all: '/ae/stats',
      us: '/ae/stats/us',
      them: '/ae/stats/them',
    },
    events: {
      all: '/ae/events',
      us: '/ae/events/us',
      them: '/ae/events/them',
    },
    problems: {
      all: '/ae/problems',
      us: '/ae/problems/us',
      them: '/ae/problems/them',
    },
    timeline: {
      all: '/ae/timeline',
      us: '/ae/timeline/us',
      them: '/ae/timeline/them',
    },
    download: {
      all: '/ae/events/download',
      us: '/ae/events/us/download',
      them: '/ae/events/them/download',
    },
  },
  recalls: {
    events: '/recalls/events',
    timeline: '/recalls/timeline',
  },
  invites: {
    new: '/invitations',
    resend: '/invitations/resend',
    pending: '/invitations/pending',
  },
  tenants: {
    info: '/tenants/info',
    metadata: '/tenants/metadata',
    plan: '/tenants/plan',
    select: '/tenants/select',
  },
  filters: {
    brands: '/ae/brands',
    mfgs: '/ae/manufacturers',
  },
  product_hub: {
    product_families: '/producthub/families',
    products: '/producthub/products',
    customer_complaints: '/customer_complaints',
    customer_complaints_upload: '/customer_complaints/import',
    customer_complaint_datasets: '/customer_complaints/datasets',
  },
  intellirisk: {
    datasets: '/customer_complaints/datasets',
    analyses: (datasetId: string) => `/complaint_datasets/${datasetId}/risk_analyses`,
    imdrf_codes: '/risk_analyses/imdrf',
  },
  users: {
    selectTenant: '/users/last-logged-in-tenant-id',
  },
};

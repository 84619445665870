import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

import Logo from 'src/components/common/logo';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function AuthClassicLayout({ children }: Props) {
  const theme = useTheme();

  const renderLogo = <Logo disabledLink />;

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        maxWidth: 440,
        px: 3,
        py: 3,
        bgcolor: 'background.paper',
        boxShadow: theme.customShadows.z4,
        minHeight: { xs: '100vh', md: 'auto' },
        borderRadius: {
          xs: 0,
          md: 1.25,
        },
      }}
    >
      {renderLogo}
      {children}
    </Stack>
  );

  return (
    <Stack
      component="main"
      sx={{
        display: 'flex',
        alignItems: { md: 'center' },
        justifyContent: { md: 'center' },
        minHeight: '100vh',
        bgcolor: '#f2f2f2',
      }}
    >
      {renderContent}
    </Stack>
  );
}

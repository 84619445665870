import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export function listItemButton(theme: Theme) {
  return {
    MuiListItemButton: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.paper,
          display: 'block',
          position: 'relative',
          transition: 'all 0.1s ease',
          ':hover': {
            backgroundColor: theme.palette.grey[100],
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.lighter,
          },
        },
      },
    },
  };
}

import { lazy, Suspense } from 'react';

import { AuthGuard, MFAGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';
import { MaudeProvider } from 'src/context/maude-provider';

// ----------------------------------------------------------------------

const Risk = lazy(() => import('src/pages/dashboard/risk'));
const Recalls = lazy(() => import('src/components/recalls'));
const UserProfile = lazy(() => import('src/pages/dashboard/profile'));
const NewDashboard = lazy(() => import('src/pages/dashboard/adv'));
const AdverseEvents = lazy(() => import('src/components/adverse'));
const ProcodeSearch = lazy(() => import('src/pages/procode-search'));
// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <MFAGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <MaudeProvider>
                <NewDashboard />
              </MaudeProvider>
            </Suspense>
          </DashboardLayout>
        </MFAGuard>
      </AuthGuard>
    ),
    children: [
      { path: 'risk', element: <Risk /> },
      { path: 'recalls', element: <Recalls /> },
      { path: 'profile', element: <UserProfile /> },
      { path: 'procode-search', element: <ProcodeSearch /> },
      { path: 'adverse-events', element: <AdverseEvents /> },
    ],
  },
];

import { Theme } from '@mui/material/styles';
import { DialogProps } from '@mui/material/Dialog';
import { Transition } from 'src/components/transition';
// ----------------------------------------------------------------------

export function dialog(theme: Theme) {
  return {
    MuiDialog: {
      defaultProps: {
        fullWidth: true,
        TransitionComponent: Transition,
      },
      styleOverrides: {
        root: {
          // fix for Dialog not properly unmounting refer the below githublink for more info
          // https://github.com/mui/material-ui/issues/32286#issuecomment-1287951109
          [`&:has(> div.MuiBackdrop-root[style*="opacity: 0"])`]: {
            pointerEvents: 'none',
          },
        },
        paper: ({ ownerState }: { ownerState: DialogProps }) => ({
          boxShadow: theme.customShadows.dialog,
          borderRadius: theme.shape.borderRadius,
          ...(!ownerState.fullScreen && {
            margin: theme.spacing(2),
          }),
        }),
        paperFullScreen: {
          borderRadius: 0,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3),
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(0, 3),
        },
        dividers: {
          borderTop: 0,
          borderBottomStyle: 'dashed',
          paddingBottom: theme.spacing(3),
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3),
          '& > :not(:first-of-type)': {
            marginLeft: theme.spacing(1.5),
          },
        },
      },
    },
  };
}

import { useMemo } from 'react';
import HubIcon from 'src/assets/icons/header/hub';
import MonitorIcon from 'src/assets/icons/header/monitor';
import DatawarehouseIcon from 'src/assets/icons/header/datawarehouse';
import Reportcon from 'src/assets/icons/header/report';
import NinjaIcon from 'src/assets/icons/header/ninja';
import NonConformanceIcon from 'src/assets/icons/header/non-conformance-db';

import QueryStatsIcon from '@mui/icons-material/QueryStats';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';

import TenantsAPI from 'src/api/tenant';
import DBIcon from 'src/assets/icons/db';
import CloudIcon from 'src/assets/icons/cloud';
import DangerIcon from 'src/assets/icons/danger';
import { NavItemBaseProps } from 'src/components/nav-section';

//

import { useQuery } from '@tanstack/react-query';

import { paths } from 'src/routes/paths';

// ---------------------------------------------

const ICONS = {
  storage: <DBIcon />,
  intel: <CloudIcon />,
  risk: <DangerIcon />,
  hub: <HubOutlinedIcon />,
  query: <QueryStatsIcon />,
  doc: <AssessmentOutlinedIcon />,
  analytics: <TrendingUpOutlinedIcon />,
};

// ----------------------------------------------------------------------

type NavItemID = 'productCentral' | 'postMarketTools' | 'preMarketTools' | 'askLumina';

export interface NavItem {
  id: NavItemID;
  label: string;
  isDisabled: boolean;
  route: string;
  children: NavItemBaseProps[];
}

export function useNavData() {
  const { data: tenantPlan } = useQuery({
    queryKey: ['get_tenant_plan'],
    queryFn: () => TenantsAPI.getTenantPlan(),
  });

  const data = useMemo(() => {
    const navItems: NavItem[] = [
      {
        id: 'productCentral',
        label: 'Product Central',

        isDisabled: false,
        route: 'product-hub',
        children: [
          {
            ref: 'productHub',
            title: 'Hub',
            icon: <HubIcon height={24} width={24} />,
            path: `${tenantPlan?.product_hub ? paths.product_hub.root : '#'}`,
            disabled: false,
          },
          {
            ref: 'monitor',
            title: 'Monitoring',
            icon: <MonitorIcon height={24} width={24} />,
            path: '#',
            disabled: true,
          },
          {
            ref: 'nonConformance',
            title: 'NonConformance Database',
            icon: <NonConformanceIcon height={24} width={24} />,
            path: '#',
            disabled: true,
          },
          {
            ref: 'dataWarehouse',
            title: 'Data Warehouse',
            icon: <DatawarehouseIcon height={24} width={24} />,
            path: '#',
            disabled: true,
          },

          {
            ref: 'reportingTemplates',
            title: 'Reporting Templates',
            icon: <Reportcon height={24} width={24} />,
            path: '#',
            disabled: true,
          },
          {
            ref: 'ninjaCentral',
            title: 'Ninja Central',
            icon: <NinjaIcon height={24} width={24} />,
            path: '#',
            disabled: true,
          },
        ],
      },
      {
        id: 'postMarketTools',
        label: 'Post-Market Tools',
        isDisabled: false,
        route: 'post-market',
        children: [
          {
            ref: 'smartViz',
            title: 'Smart Viz and Analytics',
            path: `${paths.dashboard.root}`,
            icon: ICONS.query,
            disabled: !tenantPlan?.smart_viz,
          },
          {
            ref: 'recalls',
            title: 'Recalls',
            path: `${paths.dashboard.recalls}`,
            icon: ICONS.query,
            hide: true,
          },
          {
            ref: 'procodeSearch',
            title: 'Procode Search',
            path: `${paths.procode_search.root}`,
            icon: ICONS.query,
            hide: true,
          },
          {
            title: 'IntelliRisk™',
            path: paths.intellirisk.root,
            icon: ICONS.risk,
            // customMsg: 'Coming Soon',
            disabled: !tenantPlan?.intellirisk,
          },
          {
            title: 'Nonconformance Analytics',
            path: `#`,
            icon: ICONS.analytics,
            disabled: !tenantPlan?.nca,
          },
          {
            ref: 'competitiveIntelligence',
            title: 'Competitive Intelligence',
            path: `#`,
            icon: ICONS.intel,
            disabled: !tenantPlan?.competitive_intelligence,
          },
          {
            ref: 'insights',
            title: 'Insights and Reports',
            path: `#`,
            icon: ICONS.doc,
            disabled: !tenantPlan?.insights,
          },
          {
            ref: 'dataSource',
            title: 'Data Sources',
            path: `#`,
            icon: ICONS.storage,
            disabled: !tenantPlan?.datasources,
          },
        ],
      },
      {
        id: 'preMarketTools',
        label: 'Pre-Market Tools',
        isDisabled: true,
        route: 'pre-market',
        children: [],
      },
      {
        id: 'askLumina',
        label: 'Ask Lumina',
        isDisabled: true,
        route: 'ask-lumina',
        children: [],
      },
    ];
    return { navItems };
  }, [tenantPlan]);

  return data;
}

import { lazy, Suspense } from 'react';

import { AuthGuard, MFAGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const ProcodeSearch = lazy(() => import('src/pages/procode-search'));
// ----------------------------------------------------------------------

export const procodeSearchRoutes = [
  {
    path: 'procode-search',
    element: (
      <AuthGuard>
        <MFAGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <ProcodeSearch />
            </Suspense>
          </DashboardLayout>
        </MFAGuard>
      </AuthGuard>
    ),
  },
];

import { AppBar, Toolbar, Typography, Box, Stack, Link } from '@mui/material';
import { RouterLink } from 'src/routes/components';

import Logo from 'src/components/common/logo';

import { useAuthContext } from 'src/auth/hooks';
import { useTheme } from '@mui/material/styles';

import LuminaIcon from 'src/assets/icons/header/lumina';
import AccountPopover from '../common/account-popover';
import { NavItem } from './config-navigation';

interface HeaderProps {
  activeSectionID: string;
  navItems: NavItem[];
}

export default function Header({ activeSectionID, navItems }: HeaderProps) {
  const { tenant } = useAuthContext();
  const theme = useTheme();

  return (
    <AppBar
      position="fixed"
      sx={{
        background: theme.palette.background.paper,
        boxShadow: theme.customShadows.z1,
        minHeight: '48px',
      }}
    >
      <Toolbar
        variant="dense"
        sx={{
          minHeight: '48px',
          height: '48px',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <Stack direction="row" sx={{ position: 'absolute', left: '1.5%' }}>
          <Logo single />
          <Typography
            variant="overline"
            color={theme.palette.text.primary}
            sx={{ alignSelf: 'center', ml: 1.5 }}
          >
            {tenant?.name}
          </Typography>
        </Stack>
        <Box />
        <Stack
          component="nav"
          direction="row"
          spacing={2}
          sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}
        >
          {navItems.map((item) => (
            <Link
              component={RouterLink}
              key={item.id}
              href={item.children[0]?.path}
              underline="none"
              aria-disabled={item.isDisabled}
              sx={{
                display: 'flex',
                pointerEvents: item.isDisabled ? 'none' : 'auto',
                alignItems: 'center',
                color: item.isDisabled ? theme.palette.text.disabled : theme.palette.text.primary,
                borderBottom: 2,
                borderColor:
                  item.id === activeSectionID ? theme.palette.primary.main : 'transparent',
                py: 1,
                px: 2,
                height: '48px',
              }}
            >
              {item.id === 'askLumina' && <LuminaIcon height={28} width={28} />}
              <Typography variant="body2" sx={{ ml: 1, fontSize: 14, fontWeight: 600 }}>
                {item.label}
              </Typography>
            </Link>
          ))}
        </Stack>
        <AccountPopover />
      </Toolbar>
    </AppBar>
  );
}

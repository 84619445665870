import logoFull from '@logo/logo_full.svg';
import logoSingle from '@logo/logo_single.svg';

//

import { forwardRef } from 'react';

import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';

import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  single?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, single = false, sx, ...other }, ref) => {
    const url = single ? logoSingle : logoFull;

    const imgSx = {
      width: single ? 50 : 200,
      height: 50,
      ...sx,
    };

    const logo = <Box component="img" src={url} sx={imgSx} />;

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
